import React, { useState } from 'react';
import './landing.scss';
import { NavLink, Outlet } from 'react-router-dom';

export default function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const sidemenu = [
    { url: 'pending-video', name: 'Video Queue' },
    { url: 'reviewd-video', name: 'My Reviews' },
  ];


  const handleButtonClick = (name) => {
    alert(`Button clicked for ${name}`);
  };

  return (
    <div className='wrp'>
      {/* Hamburger Menu Icon */}
      {/* Sidebar */}
      <nav className={`sidenav ${isMenuOpen ? 'open' : ''}`}>
        {/* <button className='hamburger-menu' onClick={toggleMenu}>
          <i className={`fa-solid ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </button> */}
        <ul>
          {sidemenu.map((ele, index) => (
            <li key={index} className='menu-item'>
              <NavLink to={ele.url} activeClassName="active">
                <i className="fa-solid fa-video"></i>
                {ele.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      {/* Main Content */}
      <div className='content'>
      <div className="toggle_button" onClick={toggleMenu}>
          <button
            // onClick={myFunction}
            className={isMenuOpen ? "menuicons" : ""}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div style={{ padding: '0 2rem' }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
