import React from 'react'
import styles from './GameSupport.module.scss'

const GameSupport = () => {
    return (
        <div className={styles.support_container}>
            <p>
                If you have further questions or feedback or need support, please email us at
            </p>
            <p><a href="mailto:support@visist.ai">support@visist.ai</a></p>
        </div>
    )
}

export default GameSupport