import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from "../Pending/Pending.module.scss"
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Dialog, IconButton, DialogContent, DialogTitle, Button, Pagination, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CoachFeedback from '../CoachFeedback';
import { getAllPostCounts, getFetchGamePostFileThumbnail, getVideos } from '../../../redux/network/videoApi';
import { useDispatch, useSelector } from 'react-redux';
import thumbnail from './thumbnail.jpg';
import { error_GetFetchVideoContent, isStarted_GetFetchVideoContent, success_GetFetchVideoContent } from '../../../redux/reducers/videoSlice';
import { GET_POST_CONTENT } from '../../../config/endpoints';
import { useNavigate } from 'react-router-dom';

const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

// Helper function to calculate the start and end of time ranges
const getTimeRange = (range) => {
    const now = new Date();
    let start, end;
    switch (range) {
        case 'lastOneWeek':
            start = new Date(now.setDate(now.getDate() - 7));
            end = new Date();
            break;
        case 'lastMonth':
            start = new Date(now.setMonth(now.getMonth() - 1));
            end = new Date();
            break;
        case 'last3Months':
            start = new Date(now.setMonth(now.getMonth() - 3));
            end = new Date();
            break;
        default:
            start = null;
            end = null;
    }
    return { start: start?.getTime(), end: end?.getTime() };
}
const Reviewd = () => {
    const env = process.env.REACT_APP_ENV;
    const dispatch = useDispatch();
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const userId = localStorage.getItem("BQS__ID");
    const [avoidUpdateTrigger, setAvoidUpdateTrigger] = useState(false);
    const [videoThumbnails, setVideoThumbnails] = useState({});
    console.log("videos==============", videos)
    const [page, setPage] = useState(1); // State to manage the current page
    const [totalPages, setTotalPages] = useState(1); // Total pages from the response
    const [sortOption, setSortOption] = useState('default');
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [updateTrigger, setUpdateTrigger] = useState(false);


    const postCountData = useSelector(state => state.video.videosCount);
    const COUNT = Math.ceil(postCountData?.data?.count / limit)
    const videocoutn = postCountData?.data?.count

    const { isStarted, isError, error, data } = useSelector(state => state.video.video);

    let filteredVideos = [];
    if (env === "standalone") {
        filteredVideos = data.filter(video => video.state === "reviewed"); // Update the outer variable
        console.log("filteredVideos", filteredVideos); // This should now reflect correctly
    }


    console.log("data========", data)

    //All Posts Details
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            dispatch(getVideos(jwtToken, {
                "condition": {
                    "state": ["reviewed"]
                },
                "sort": {
                    "updatedAt": -1
                },
                "skip": skip,
                 "limit": limit,
            }));
        }
    }, [jwtToken, userId, page, sortOption, skip, limit]);

    //All Posts Count
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            dispatch(getAllPostCounts(jwtToken, {
                "condition": {
                    "state": ["reviewed"]
                },

            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger, skip, limit]);


    useEffect(() => {
        if (data?.length > 0 && jwtToken && env !== "standalone") {
            const fetchThumbnail = async (reviewPostId, tnPath) => {
                try {
                    const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
                    setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
                } catch (error) {
                    console.error('Error fetching thumbnail:', error);
                }
            };
            data?.forEach((item) => {
                fetchThumbnail(item._id, item.game[0]?.tnPath);
            });
        }
    }, [data, jwtToken, userId]);




    // Handle page change
    const handleChange = (event, value) => {
        setPage(value);
        setSkip((value - 1) * limit);
        window.scrollTo(0, 0);
    };;



    const openModal = (video) => {
        setSelectedVideo(video);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVideo(null);
        setIsModalOpen(false);
    };


    const handleClickDel = async (video) => {
        try {
            const response = await fetch(`http://localhost:3000/api/videos/${video._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                // On success, remove the specific video from the state
                setVideos(prevVideos => prevVideos.filter(v => v._id !== video._id));
                toast.success('Video deleted successfully');
            } else {
                toast.error('Error deleting video');
            }
        } catch (error) {
            toast.error('Request failed', error);
        }
    };


    const navigate = useNavigate();


    async function handlePlayClick(video) {
        const postId = video._id;
        const videoLinkInObjectStore = video.game[0].path;

        setSelectedVideo(video);
        localStorage.setItem("reviewPostId", postId);
        localStorage.setItem("reviewPost", JSON.stringify(video));
        localStorage.setItem("videoLinkInObjectStore" , videoLinkInObjectStore);
        navigate(`/coach_flow/reviewd-video/feedback/${postId}`);

        if (env === "standalone") {
            return null;
        }
        if (env !== "standalone") {
            const { firstName, lastName } = video?.poster?.profileData?.name || {};
            localStorage.setItem("firstname", firstName || "");
            localStorage.setItem("lastname", lastName || "");
            console.log("First Name:", firstName, "Last Name:", lastName);
        }

    }
    return (
        <div>
            {/* Thumbnail Grid */}
            <div className={styles.filters}>
                {/* <div style={{ display: "flex", alignItems: 'center', gap: "2rem" }}>
                    <div className={styles.searchbox_short}>
                        <i style={{ marginRight: "1rem" }} className="fa fa-search"></i>
                        <input type="text" placeholder="Search by age and athlete" />
                    </div>
                    <i class="fa-solid fa-arrow-up-wide-short" style={{ color: "#777", fontSize: "28px" }}></i>

                </div> */}
                <h1>My Reviews</h1>

                {/* <div className={styles.counts}>
                    Remaining video count <span>{data?.length || 0}/{videocoutn || 0}</span>
                </div> */}
            </div>
            <div className={styles.video_section}>
                {/* <h1>My Reviews</h1> */}
                <div className={styles.wrp_video}>

                    {

                        (env === "standalone" ? filteredVideos.length > 0 : data.length > 0) ? (
                            (env === "standalone" ? filteredVideos : data)?.map((video) => {
                                console.log("video", video);
                                const thumbnailUrl = videoThumbnails[video._id] || thumbnail;

                                return (
                                    <div className={styles.video_wrapper} key={video._id}>
                                        <div className={styles.data_icons_container}>
                                            <div className={styles.date_cont}>
                                                <span></span>
                                                <p>{dataFormatter(video.updatedAt)}</p>
                                            </div>
                                        </div>

                                        <div className={styles.video_box}>
                                            <div
                                                className={styles.video_cont}
                                                style={{ backgroundImage: `url(${thumbnailUrl})` }}
                                            >
                                                <Button onClick={() => handlePlayClick(video)}>View Report</Button>
                                            </div>
                                            <div className={styles.icons}>
                                                <div
                                                    className={styles.play_icon}
                                                    onClick={() => handlePlayClick(video)}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlay} />
                                                    <span>Play</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p style={{height:"30vh"}}>No videos available</p>
                        )

                    }

                </div>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {COUNT > 0 && (
                        <Stack spacing={2} sx={{ marginBlock: "2rem", alignItems: "center" }}>
                            <Pagination
                                count={COUNT}
                                page={page}
                                onChange={handleChange}
                                size="large"
                                color="primary"
                            />
                        </Stack>
                    )}
                </div> */}
            </div>


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </div>
    );
};

export default Reviewd;
