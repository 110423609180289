import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent,IconButton, DialogActions, Button, TextField, Box } from '@mui/material';
import { POST_REGISTRATION_OTP, POST_REGISTER_LOGIN } from '../../config/endpoints';
import CloseIcon from '@mui/icons-material/Close';
import "./SignupPage.scss";
import LoadingButton from  '../LoadingButton/LoadingButton';
import { handleOtpError, handleRegisterError } from '../../config/errors'
import { useNavigate,Link } from 'react-router-dom';
import { setIsLoginDialog } from "../../redux/reducers/loginSlice";
import { useDispatch } from 'react-redux';
import { SERVER_MESSAGE } from "../../config/settings";
import validator from "email-validator";

const SignupPage = () => {

  const dispatch = useDispatch()

  let [data, setData] = useState({
    name: '',
    dob: '',
    gender: '',
    height: '',
    weight: '',
    email: '',
    coach: '',
    phone: '',
    countryCode: '+1', // default country code
  });
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const playerRole = 'player';
  const coachRole = 'coach';
  const heightLowerLimit = 75;
  const heightHigherLimit = 225;
  const weightLowerLimit = 25;
  const weightHigherLimit = 250;

  useEffect(() => {
    if (jwtToken) {
      navigate("/")
    }
  }, [jwtToken]) 


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsLoginDialog(false))  
  }, []);


const navigate=useNavigate()
 const [resendDisabled, setResendDisabled] = useState(false);
   const [timer, setTimer] = useState(60);
  let intervalId;
  //resend timer
  useEffect(() => {
    if (resendDisabled) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setResendDisabled(false);
            return 60;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendDisabled]);

//resend otp

const [isError, setIsError] = useState(false);
const [errorMsg, setErrorMsg] = useState('');
const [loading, setLoading] = useState(false);
const [isSuccess, setIsSuccess] = useState(false);
const [alertMsg, setAlertMsg] = useState("")

const otpInputs = React.useRef([]);


// Handle OTP input field changes
const handleChangeOTP = (index, value, e) => {
  const newOtp = [...otp];
  newOtp[index] = value.slice(0, 1); // Ensure only single digit is allowed
  setOtp(newOtp);

  // Move to next input if not the last one and value is valid
  if (value && index < otp.length - 1) {
    otpInputs.current[index + 1]?.focus();
  }

  // If backspace is pressed on an empty field, focus the previous one
  if (!value && e.inputType === 'deleteContentBackward' && index > 0) {
    otpInputs.current[index - 1]?.focus();
  }
};

// Handle keyboard navigation between OTP fields
const handleKeyDown = (e, index) => {
  if (e.key === 'Backspace' && index > 0 && !otp[index]) {
    otpInputs.current[index - 1]?.focus();
  }
  if (e.key === 'ArrowRight' && index < otp.length - 1) {
    otpInputs.current[index + 1]?.focus();
  }
  if (e.key === 'ArrowLeft' && index > 0) {
    otpInputs.current[index - 1]?.focus();
  }
};

// Handle resend OTP
const handleResendOTP = async (e) => {
  e.preventDefault();
  setErrorMsg("")
  const obj = {
    type: 'registration',
    phone: {
      cc: `${countryCode}`,
      number: `${phone}`,
    },
  };

  try {
    const response = await axios.post(POST_REGISTRATION_OTP, obj, {
      headers: { 'Content-Type': 'application/json' },
    });
    setErrorMsg(errorMsg || "OTP has been sent");
    if (response.status === 200) {
      // alert('OTP resent successfully!');

      setTimer(60);
    }
  } catch (err) {
    console.error('Error:', err);
    setErrorMsg(errorMsg || "Failed to resendOTP ,please try again");
    setResendDisabled(false);
  }
  setResendDisabled(true);
  setIsSuccess(false)
 
};


  let { name, dob, gender, height, weight, email, coach, phone, countryCode } = data;
  let [error, setError] = useState({ phone: '' });

  const [showOTPBox, setShowOTPBox] = useState(false); // state for OTP modal visibility
  let [otp, setOtp] = useState(['', '', '', '']);
  var enteredOTP=otp.join(' ')

  let handleChange = (e) => {
    setErrorMsg("")
    const { name, value } = e.target;
  
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError((prevError) => ({
      ...prevError,
      [name]: "",
    }));
    if (name === 'height' || name === 'weight') {
      const regex = /^$|^\d{1,3}(\.\d{0,2})?$/;
      if (regex.test(value) || value === '') {
        setData({ ...data, [name]: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  
    if (name === 'phone') {
      if (!/^\d*$/.test(value)) {
        setError({ ...error, phone: 'Only numeric values are allowed' });
        
      } else if (value.length !== 0 && value.length !== 10) {
        setError({ ...error, phone: 'Mobile number must be 10 digits' });
   
      } else {
        setError({ ...error, phone: '' });
      
      }
    }

    // if ( name === 'dob')
    // {
    //   dob = 
    // }
  };




// Email Validation Function
function validateEmail(email) {
  if (!validator.validate(email)) {
    return "Please enter a valid email address";
  }



  // Additional domain and TLD validation
  const domainPart = email.split("@")[1]; // Extract the domain part
  if (domainPart) {
    const domainSections = domainPart.split(".");
    const tld = domainSections[domainSections.length - 1];

    // Check if TLD length is at least 2 characters
    if (tld.length < 2) {
      return "Please enter a valid email address with a proper domain";
    }

    // Check if domain length is sufficient
    if (domainSections[0].length < 2) {
      return "Please enter a valid email address with a proper domain name";
    }
  }

  return ""; // No error
}

// Number Validation Function
function checkIfItIsOnlyNumber(possibleNumber) {
  const pattern = /^[0-9]+$/;
  return pattern.test(possibleNumber);
}


// Height Validation Function
function checkIfItValidHeight(height) {
  return height >= heightLowerLimit && height <= heightHigherLimit ? true  : false;
}

// Weight Validation Function
function checkIfItValidWeight(weight) {
  return weight >= weightLowerLimit && weight <= weightHigherLimit ? true  : false;
}



  let submit = async (e) => {
    e.preventDefault();
    
   // Initialize error state
   let formErrors = {};

  // Validate all required fields
  if (!name) formErrors.name = "Please your enter name";
  if (!dob) formErrors.dob = "Please enter your date of birth";
  if (!gender) formErrors.gender = "Please enter your gender";
  if (!height) 
    {
      formErrors.height = "Please enter your height";
    }
  else if (!checkIfItIsOnlyNumber(height)) {
    formErrors.height = "Please enter number only";
  }

  else if (!checkIfItValidHeight(height)) {
    formErrors.height = `Height has to be range of ${heightLowerLimit} cms - ${heightHigherLimit} cms`;
  }


  if (!weight) 
    {
      formErrors.weight = "Please enter your weight";
    }
  else if (!checkIfItIsOnlyNumber(weight)) 
      { formErrors.weight = "Please enter number only";
      }
  else if (!checkIfItValidWeight(weight)) 
      { formErrors.weight = `Weight has to be range of ${weightLowerLimit} kgs - ${weightHigherLimit} kgs`;;
      }

  if (!phone) formErrors.phone = "Phone number is required.";


  // Email Validation
  if (!email) 
    {
      formErrors.email = "Please enter your email";
    }
    else {
        const emailError = validateEmail(email);
        if (emailError) formErrors.email = emailError;
    }
  // Phone Error Check
  if (error && error.phone) {
    formErrors.phone = error.phone;
  }

  // If there are errors, set them and stop submission
  if (Object.keys(formErrors).length > 0) {
    setError(formErrors);
    return;
  }

    const obj = {
      "type": "registration",
      "phone": {
        "cc": `${countryCode}`,
        "number": `${phone}`
      }
    };

    try {
      const response = await axios.post(POST_REGISTRATION_OTP, obj, {
        headers: {
          "Content-Type": "application/json",
        },
      });


      if (response.status === 200) {
        setShowOTPBox(true); // Show OTP input modal
        setResendDisabled(true);
        // let errorMsg = SERVER_MESSAGE ? response.data.message : handleOtpError(response)
        // console.log("respons==>",response.data.message)
        setErrorMsg( "OTP has been sent");
        localStorage.setItem("SIGN_UP_BQS__ID", response?.data?.data?._id)
      }
      else {
        setErrorMsg(handleOtpError(response)); // Set phone-specific error
        setIsSuccess(false)
      }
    }
     catch (err) {
      console.error("Error:", err);
      console.log("error response==>",err.response.data.message);

      const errorMessage = handleOtpError(err.response.data) || "Something went wrong";
      console.log("error response==>", errorMessage);
      setError({ phone: errorMessage }); // Set phone-specific error
    
    }
  };





  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];  // Create a new copy of the OTP array
    newOtp[index] = value.slice(0, 1);  // Only keep the first character
    setOtp(newOtp);  // Update the OTP state with the new array
  
    // Automatically focus the next input field if the current one is filled
    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };
  //handling verify otp

  const handleVerifyOTP=async()=>{
    setLoading(true)
    setIsSuccess(false)
    
    var obj = {
      "otpToken": {
        "type": "registration",
        "otp": `${enteredOTP.replace(/\s+/g, '')}`
      },

      "profileData": {
        "name": {
          "firstName" : `${name}`
        },
        "dob":`${formatDateToDDMMYYYY(dob)}`,
        "gender":`${gender}`,
        "weight": `${weight}`,
        "height": `${height}`,
        "gamingProfile": {
          "coachName": `${coach}`,
        }
      },
      "email":`${email}`,
    }
  
  const BQS_ID = localStorage.getItem("SIGN_UP_BQS__ID")
  // const BQS_ID = localStorage.getItem('BQS__ID');
// if (!BQS_ID) {
//     console.error("BQS_ID is not available in localStorage.");
//     setErrorMsg("Session expired. Please log in again.");
//     // navigate('/login'); // Redirect to login or appropriate page
//     return;
// }

const url = POST_REGISTER_LOGIN + "/" + BQS_ID;

console.log("url", url);

fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
})
    .then(res => res.json())
    .then(response => {
        console.log("SIGN UP POST RESPONSE: ", response);
        const regErrorMessage = handleRegisterError(response);
        if (response && 'code' in response && response['code'] == 200) {
            setIsSuccess(true);
            alert("Signup successful")
            setAlertMsg(regErrorMessage || "Sign Up successful. Welcome!");
            localStorage.setItem('BQS__ID', response.data["_id"]);
            localStorage.setItem('BQS_TOKEN', response.data.token.token);
            localStorage.setItem('BQS_ROLE', response.data.role);
            // localStorage.setItem('BQS_USER_NAME', response.data?.profileData?.name?.firstName);
            // alert(response.data.profileData.name.firstName)
            if(localStorage.getItem('BQS_ROLE') === playerRole)
            {
              navigate('/upload');
            }
            else {
              navigate('/coach_flow/pending-video');
            }
            
        } else {
            //setErrorMsg(regErrorMessage || "Something went wrong! Try again later.");
            setErrorMsg(handleRegisterError(response));
            // alert("Something went wrong! Try again later.")
        }
    })
    .catch(error => {
        console.error("Exception Login API: ", error);
        setErrorMsg(error.message || "Something went wrong! Try again later.");
    })
    .finally(() => setLoading(false));

  }


  const formatDateToDDMMYYYY = (dob) => {
    if (!dob) return "";
    const [year, month, day] = dob.split("-");
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="personalDetails_con">
        <h1 className="heading">SIGN UP</h1>
        <form>
          <fieldset className="content_con">
            <div className="content_box">
              <div className="personal-fields-container">
                <div className='input-field-cont'>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  /> {error.name && <p className="error-message">{error.name}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="dob">DOB</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    value={dob}
                    onChange={handleChange}
                  /> {error.dob && <p className="error-message">{error.dob}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="gender">Gender</label>
                  <select name="gender" id="gender" value={gender}  onChange={handleChange}>
                  <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>  {error.gender && <p className="error-message">{error.gender}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="weight">Weight</label>
                  <input
                    type="text"  
                    name="weight"
                    id="weight"
                    placeholder="Enter your weight in kg.."
                    onChange={handleChange}
                    value={weight}
                  /> {error.weight && <p className="error-message">{error.weight}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="height">Height</label>
                  <input
                    type="text"
                    name="height"
                    id="height"
                    placeholder="Enter your height in cm.."
                    onChange={handleChange}
                    value={height}
                  /> {error.height && <p className="error-message">{error.height}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="email">Email Id</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />  {error.email && <p className="error-message">{error.email}</p>}
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="coach">Coach (optional)</label>
                  <input type="text" id="coach" name="coach" value={coach} onChange={handleChange}/>
                </div>
                <div className='input-field-cont'>
                  <label htmlFor="phone">Phone number</label>
                  <div className='mobile-number-field-con'>
                    <select
                      name="countryCode"
                      id="countryCode"
                      value={countryCode}
                      onChange={handleChange}
                    >
                      <option value="+91">+91 (India)</option>
                      <option value="+1">+1 (USA)</option>
                      <option value="+44">+44 (UK)</option>
                      <option value="+61">+61 (Australia)</option>
                    </select>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      value={phone}
                      onChange={handleChange}
                    />
                  </div>
                  {error.phone && <p className="error-message">{error.phone}</p>}
                </div>
                
              </div>
              <div className='input-field-cont'>
                  <div className='button-container'>
                    <button  className="otpBtn" type='submit' onClick={submit} > Send OTP </button>
                  </div>
                </div>
            </div>
          </fieldset>
        </form>
      </div>

 
{
  showOTPBox && (
    <Dialog
      open={showOTPBox}
      onClose={() => setShowOTPBox(false)} // Close handler
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setShowOTPBox(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
      <section className="header-section">
      <div className="heading-otp">
      <h5 variant="h1">Signup with OTP</h5>
      </div>
      {/* <div className="divider"></div> */}
      </section>
        <section className="otp-section">
       
          <h5 variant="h2">Enter OTP</h5>
          <div className="otp-inputs">
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={(el) => (otpInputs.current[index] = el)}
                type="tel"
                pattern="[0-9]*"
                inputMode="numeric"
                value={digit}
                onChange={(e) => handleChangeOTP(index, e.target.value, e)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
              />
            ))}
          </div>
          <p>Enter the OTP sent to +91{phone}</p>
          <p
            style={{
              color: `${isError ? 'red' : 'green'}`,
              fontSize: 12,
              textAlign: 'center',
              marginTop: '10px',
              maxWidth: '250px',
            }}
          >
            {errorMsg}
          </p>
           <p className='terms_cond'>
              By Signing up, you agree to our  <Link to="/privacy_policy">Privacy Policy</Link> and <Link to="/term_service">Terms of Service.</Link>
            </p>
         
          <p className="resend_otp">
            {resendDisabled ? (
              `Resend OTP in ${timer} seconds`
            ) : (
              <a href="#" onClick={handleResendOTP}>
                Resend OTP
              </a>
            )}
          </p>
        </section>
      </DialogContent>
      <DialogActions>

        <div id="buton">
        <button
          variant="contained"
          className="otpBtn"
          fullWidth
          onClick={handleVerifyOTP}
          disabled={loading || isSuccess}
        >
          {loading ? <LoadingButton /> : 'Signup'}
        </button>
        </div>
       
      </DialogActions>
    </Dialog>
  )
}


    </>
  );
};

export default SignupPage;
