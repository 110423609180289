import React from 'react'
import styles from './GamePostSidebar.module.scss'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { useDispatch } from 'react-redux';
import { setUploadGuidelineVideoDialogOpen } from '../../../redux/reducers/loginSlice';
import UploadGuidelineVideoModel from '../../UploadGuidelineVideoModel/UploadGuidelineVideoModel';

const guidelines = [
    { id: "1", guideline: "Before recording, position your camera 2.5mts from the baseline and 3mts high with a tripod for steady, clear visuals of the court." },
    { id: "2", guideline: "Ensure good lighting and reduce background noise for better video quality." },
    { id: "3", guideline: "Record videos (MP4, MOV, or MKV format), keeping them under 200 MB or 10mins duration, and upload here!" },
];

const GamePostSidebar = ({isMenuOpen, toggleMenu}) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const isGameVideoPath = pathname.includes("game-videos")

    const handleOpenUploadVideoGuidelineDialog = () => {
        dispatch(setUploadGuidelineVideoDialogOpen(true))
    }

    return (
        <div className={`${styles.GamePostSidebar} ${isMenuOpen ? styles.open : ''}`}>
            <ul >
                <li>
                    <NavLink
                        to="game-videos"
                        onClick={toggleMenu}
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-video"></i>
                        Videos</NavLink>
                </li>
                <li>
                    <NavLink
                        to="game-reports"
                        onClick={toggleMenu}
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-bars"></i>
                        Reports</NavLink>
                </li>
                <li>
                    <NavLink
                        to="game-membership"
                        onClick={toggleMenu}
                        className={({ isActive }) => (isActive ? styles.active : "")}>
                        <i className="fa-solid fa-desktop"></i>
                        Membership
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="game-supports"
                        onClick={toggleMenu}
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-phone"></i>
                        Support</NavLink>
                </li>
            </ul>

            {
                isGameVideoPath &&
                <div className={styles.guideline_video_wrap}>
                    <div className={styles.content_wrap}>
                        <div className={styles.header}>
                            <h2>Guidelines to Record and Upload</h2>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.guidelines_wrp}>
                                {guidelines.map((ele) => {
                                    return (
                                        <div key={ele.id} className={styles.guidelines_list}>
                                            <Avatar
                                                sx={{
                                                    width: {
                                                        xs: "2px",
                                                        md: "4px",
                                                        lg: "6px"
                                                    },
                                                    height: {
                                                        xs: "2px",
                                                        md: "4px",
                                                        lg: "6px"
                                                    },
                                                    padding: {
                                                        xs: "10px",
                                                        md: "12px",
                                                        lg: "14px"
                                                    },
                                                    backgroundColor: "#247E45"
                                                }}
                                            >
                                                {ele.id}
                                            </Avatar>

                                            <p>{ele.guideline}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={styles.bottom_wrap}>
                                <p onClick={handleOpenUploadVideoGuidelineDialog}>or <Link>Watch the guideline video <i className="fa-solid fa-arrow-up-right-from-square"></i></Link></p>
                            </div>
                        </div>

                    </div>
                </div>
            }

            <UploadGuidelineVideoModel />

        </div>
    )
}

export default GamePostSidebar