import { CHANGE_POST_STATUS, LOGIN_REDIRECT, POST_FEEDBACK_URL, POST_LIST_ALL_COACHES } from "../../config/endpoints";
import { error_getCoachList, isStarted_getCoachList, success_getCoachList } from "../reducers/coachSlice";
import { setIsLoginDialog } from "../reducers/loginSlice";


export const getCoaches = (jwtToken) => {
    console.log("HITTING - getCoaches", jwtToken);

    const formData = {
        "condition": {
            "role": "coach"
        }
    }

    return dispatch => {
        dispatch(isStarted_getCoachList());
        var apiUrl = new URL(POST_LIST_ALL_COACHES);
        console.log(apiUrl);
        fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(res => {
                if (res.status === 401) {
                    //Anauthorized user
                    window.location = LOGIN_REDIRECT;
                    dispatch(setIsLoginDialog(true))
                    localStorage.clear();
                    return;
                }

                return res.json();
            })
            .then(response => {
                console.log("coach list response", response)
                if (!response || !response.hasOwnProperty('code') || response.code !== 200) {
                    throw new Error('Server error: ' + response.message);
                }
                dispatch(success_getCoachList(response.data));
            })
            .catch(error => {
                console.error(error.message);
                dispatch(error_getCoachList(error.message));
            });
    }
}

export const createUpdateFeedback = async (jwtToken, formData, postId) => {
    var apiUrl = `${POST_FEEDBACK_URL}/${postId}/feedbacks`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 401) {
            // Unauthorized user
            localStorage.clear();
            window.location = LOGIN_REDIRECT;
            return;
        }

        const responseData = await response.json();

        console.log("createUpdateFeedback response:", responseData);

        if (!responseData || !responseData.hasOwnProperty('code') || responseData.code !== 200) {
            throw new Error('Server error: ' + responseData.message);
        }
        return responseData
    } catch (error) {
        throw error;
    }

}

export const getCoachFeedback = async (jwtToken, postId) => {
    var apiUrl = `${POST_FEEDBACK_URL}/${postId}/feedbacks`;

    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (response.status === 401) {
            // Unauthorized user
            localStorage.clear();
            window.location = LOGIN_REDIRECT;
            return;
        }

        const responseData = await response.json();

        if (!responseData || !responseData.hasOwnProperty('code') || responseData.code !== 200) {
            throw new Error('Server error: ' + responseData.message);
        }
        return responseData
    } catch (error) {
        throw error;
    }

}

export const changeVideoPostStatus = async (jwtToken, formData, postId) => {
    var apiUrl = `${CHANGE_POST_STATUS}/${postId}/state`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 401) {
            // Unauthorized user
            localStorage.clear();
            window.location = LOGIN_REDIRECT;
            return;
        }

        const responseData = await response.json();

        console.log("createUpdateFeedback response:", responseData);

        if (!responseData || !responseData.hasOwnProperty('code') || responseData.code !== 200) {
            throw new Error('Server error: ' + responseData.message);
        }
        return responseData
    } catch (error) {
        throw error;
    }

}


