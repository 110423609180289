import React from 'react';
import './Support.scss';

const Support = () => {
    return (
        <div className='support_container'>
            <h1 className='heading'>SUPPORT</h1>
            <p>
                If you have further questions or feedback or need support, please email us at <a href="mailto:support@visist.ai">support@visist.ai</a>
            </p>
        </div>
    );
}

export default Support;
