import React, { useEffect, useState } from 'react'
import styles from './GameCoachFeedback.module.scss'
import { useSelector } from 'react-redux';
import { getFetchGamePostFileThumbnail } from '../../../redux/network/videoApi';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import imageUrl from './dummy_big.png';

const GameCoachFeedback = () => {

    const jwtToken = localStorage.getItem('BQS_TOKEN');
    const videoContent = useSelector((state) => state.video.videoContent);
    const reviewedPostData = JSON.parse(localStorage.getItem('reviewPostData'));
    console.log("reviewedPostData", reviewedPostData)
    const reviewPostId = reviewedPostData?._id;


    // states
    const [isPlayVideo, setIsPlayVideo] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);


    // console.log("thumbnail======>", thumbnail)

    useEffect(() => {
        const fetchThumbnail = async () => {
            try {
                const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, reviewedPostData?.game[0]?.tnPath);

                console.log("thumbnailUrl======>", thumbnailUrl)
                setThumbnail(thumbnailUrl);
            } catch (error) {
                console.error('Error fetching thumbnail:', error);
            }
        };

        fetchThumbnail();
    }, [jwtToken, reviewPostId, reviewedPostData?.game?.tnPath]);

    const handleClickVideo = () => {
        setIsPlayVideo(true);
    };

    return (
        <div className={styles.GameCoachFeedback}>
            <div className={styles.heading}>
                <h5>Report</h5>
                <p>Generated 3mins ago</p>
            </div>
            <hr />

            <div className={styles.videoSection}>
                {isPlayVideo ? (
                    <div className={styles.video_cont}>
                        {videoContent.isStartedvideoContent ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', height: '100%' }}>
                                <CircularProgress />
                                <p style={{ paddingLeft: '10px', color: '#000000' }}>Loading Video ....</p>
                            </Box>
                        ) : videoContent.isvideoContentError ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', height: '100%' }}>
                                <p>{videoContent.videoContentError}</p>
                            </Box>
                        ) : (
                            <video controls autoPlay playsInline>
                                <source src={videoContent.videoContentUrl} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                ) : (
                    <div className={styles.videoImageContainer} style={{ backgroundImage: `url(${thumbnail || imageUrl})` }}>
                        <div onClick={handleClickVideo} className={styles.iconContainer}>
                            <i className='fa-regular fa-circle-play'></i>
                            <span>Play</span>
                        </div>
                    </div>
                )}
            </div>





        </div>
    )
}

export default GameCoachFeedback