import React, { useCallback, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import styles from "./Feedback.module.scss";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { useDispatch, useSelector } from "react-redux";
import { getCoachFeedback } from "../../redux/network/coachAPI";
import { Stage, Layer, Rect } from "react-konva";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isStarted_GetFetchVideoContent, success_GetFetchVideoContent } from "../../redux/reducers/videoSlice";
import { getFetchGamePostFileThumbnail } from "../../redux/network/videoApi";
import { GET_POST_CONTENT } from "../../config/endpoints";

export default function CoachFeedback() {
  const { postId: videoId } = useParams()
  const env = process.env.REACT_APP_ENV;
  const videoRef = useRef(null);
  const userId = localStorage.getItem("BQS__ID");
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const navigate = useNavigate();
  const videoContent = useSelector(state => state.video.videoContent);
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const [videoDimensions, setVideoDimensions] = useState({});
  const standAloneFeedbacks = useSelector(state => state.standAlone.feedbacks.feedbackData);
  const [annotations, setAnnotations] = useState([]);
  const firstname = localStorage.getItem('firstname');
  const lastname = localStorage.getItem('lastname');
  const [videoKey, setVideoKey] = useState(Date.now());
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isStarted, isError, error, data } = useSelector(state => state.video.video);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const thumbnailUrl = videoThumbnails[videoId] || "https://www.fisu.net/app/uploads/2023/09/badminton.jpg"
  const dispatch = useDispatch();
  const [frameRate, setFrameRate] = useState(30);

  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Store the reload event in sessionStorage when the page is about to be reloaded
  //     sessionStorage.setItem('isReloaded', 'true');
  //   };

  //   // Detect reloads
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Check if the page was reloaded
  //   if (sessionStorage.getItem('isReloaded')) {
  //     // Navigate to the other page (e.g., '/other-page')
  //     navigate('/coach_flow/reviewd-video');
  //     // Clear the sessionStorage flag after navigation
  //     sessionStorage.removeItem('isReloaded');
  //   }

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [navigate]);


  useEffect(() => {
    if (data?.length > 0 && jwtToken && env !== "standalone") {

      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
          setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
        } catch (error) {
          console.error('Error fetching thumbnail:', error);
        }
      };
      data?.forEach((item) => {
        fetchThumbnail(item._id, item.game[0]?.tnPath);
      });
    }
  }, [data, jwtToken, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const url = `${GET_POST_CONTENT}?postId=${videoId}&type=game`;
        const url = localStorage.getItem("videoLinkInObjectStore");

        dispatch(isStarted_GetFetchVideoContent());

        const response = await fetch(url, {
          method: "GET",
          // headers: {
          //   'Authorization': `Bearer ${jwtToken}`,
          // },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch video');
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        });

        const responseBlob = await new Response(stream).blob();
        const file = new File([responseBlob], 'video.mp4', { type: 'video/mp4' });
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          const res = fr.result;
          dispatch(success_GetFetchVideoContent(res));
          setVideoLoaded(true);
          setVideoKey(Date.now()); // Mark the video as loaded
          // Play the video only when the data is loaded
        });
      } catch (error) {
        // dispatch(error_GetFetchVideoContert(error.message || "Error while fetching video"));
        console.error('Error fetching video:', error);
      }
    };
    fetchData();
  }, [videoId, dispatch, jwtToken]);

  useEffect(() => {
    try {
      // Check if the player instance is available
      if (playerRef.current) {
        if (annotations.length > 0) {
          console.log("Initializing markers with annotations:", annotations);
          playerMarkers(annotations); // Initialize markers with annotations
        } else {
          // playerMarkers(annotations);
          console.warn("anotations--------play", annotations);

          playerMarkers([]);
        }
      } else {
        console.warn("Player instance is not available.");
      }
    } catch (error) {
      console.error("Error while initializing markers:", error.message);
    }
  }, [annotations]);

  const updateDimensions = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const containerWidth = video.offsetWidth;
      const containerHeight = video.offsetHeight;

      const videoAspectRatio = video.videoWidth / video.videoHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      let displayWidth, displayHeight;
      let top = 0;
      let left = 0;

      if (containerAspectRatio > videoAspectRatio) {
        // Container is wider than the video aspect ratio
        displayHeight = containerHeight;
        displayWidth = videoAspectRatio * displayHeight;

        // Center the video horizontally
        left = (containerWidth - displayWidth) / 2;
      } else {
        // Container is taller than the video aspect ratio
        displayWidth = containerWidth;
        displayHeight = displayWidth / videoAspectRatio;

        // Center the video vertically
        top = (containerHeight - displayHeight) / 2;
      }

      setVideoDimensions({
        width: displayWidth,
        height: displayHeight,
        top: top,
        left: left,
      });
    }
  };

  useEffect(() => {

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (videoRef.current) {
      resizeObserver.observe(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        resizeObserver.unobserve(videoRef.current);
      }
    };
  }, [videoRef.current?.clientWidth, videoRef.current?.clientHeight]);


  const playerMarkers = (annotations) => {
    if (!playerRef.current) {
      console.error("Player instance is not available.");
      return;
    }
    const fps = 30;
    const markersToAdd = annotations.map((annotation) => ({
      time: annotation?.timeRange?.startTime,
      text: annotation?.comments?.[0]?.body || "Annotation",
      frame: Math.round(videoRef.current * fps),
    }));

    playerRef.current.markers({
      markers: markersToAdd,
      markerStyle: {
        width: "10px",
        height: "20px",
        backgroundColor: "red",
        cursor: "pointer",
        borderRadius: "1px",
        bottom: "-8px",
      },
      markerTip: {
        display: true,
        text: (marker) => marker.text,
      },
      // onMarkerReached: (marker) => {
      //   const exactTime = marker.time;
      //   const tolerance = 0.05;
      //   if (
      //     Math.abs(playerRef.current.currentTime - exactTime) <= tolerance
      //   ) {
      //     playerRef.current.pause();
      //     console.log(
      //       `Paused at marker: ${marker.text}, Time: ${exactTime}s`
      //     );
      //   } else {
      //     playerRef.current.currentTime(exactTime);
      //     playerRef.current.pause();
      //   }
      // },
    });
  };



  const loadVideo = useCallback(() => {

    const handleTimeout = () => {
      toast.error("Video loading timed out. Please try again.");
      window.location.reload(); // Reload the browser to reset video loading
    };

    let timeoutId;

    if (videoRef.current && !playerRef.current && videoLoaded) {
      try {
        const videoUrl =
          env === "standalone" ? "/video_js.mp4" : videoContent?.videoContentUrl;

        // Determine MIME type based on file extension
        const fileType = videoUrl.split(".").pop().toLowerCase(); // Extract file extension
        let mimeType = "video/mp4"; // Default MIME type

        if (fileType === "mov") {
          mimeType = "video/quicktime"; // MIME type for .mov files
        }

        const player = videojs(videoRef.current, {
          controls: true,
          preload: "auto",
          playbackRates: [0.5, 1, 1.5, 2],
          poster: thumbnailUrl,
          sources: [
            {
              src: videoUrl,
              type: mimeType,
            },
          ],
          controlBar: {
            stayActive: true,
            fullscreenToggle: false,
            pictureInPictureToggle: false,
          },
          userActions: {
            doubleClick: false,
            hotkeys: false,
          },
          inactivityTimeout: 0,
          playsinline: true,
        });

        playerRef.current = player;
        // Set a timeout for loading
        timeoutId = setTimeout(handleTimeout, 15000); // 15 seconds timeout

        player.on("loadeddata", () => {
          clearTimeout(timeoutId); // Clear timeout once video is loaded
          let detectedFps = 30; // Default FPS
          try {
            const videoTrack = player?.tech_?.el()?.videoTracks?.[0];
            if (videoTrack && videoTrack.frameRate) {
              detectedFps = videoTrack.frameRate;
            }
          } catch {
            console.warn("Failed to detect frame rate. Defaulting to 30 FPS.");
          }
          setFrameRate(detectedFps);
        });

        player.on("timeupdate", () => {
          if (playerRef.current) {
            setCurrentTime(playerRef.current.currentTime());
          }
        });

        player.on("play", () => {
          console.log("Video is playing");
        });

        player.on("pause", () => {
          console.log("Video is paused");
          console.log(
            "Current time at pause:",
            playerRef.current?.currentTime()
          );
        });

        player.on("canplay", () => {
          setLoading(false);
          clearTimeout(timeoutId); // Clear timeout once video is ready to play
        });

        player.on("error", () => {
          clearTimeout(timeoutId); // Clear timeout if an error occurs
          const error = player.error();
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case 2: // MEDIA_ERR_NETWORK
              errorMessage = "Network error: Unable to load the video.";
              break;
            case 4: // MEDIA_ERR_SRC_NOT_SUPPORTED
              errorMessage =
                fileType === "mov"
                  ? "Unsupported format: .mov files may not be supported in this browser."
                  : "Unsupported format: The video type is not compatible.";
              break;
            default:
              errorMessage = "An error occurred while playing the video.";
          }
          console.error(errorMessage);
          toast.error(errorMessage);
        });

        window.addEventListener("resize", updateDimensions);
        updateDimensions();
      } catch (error) {
        console.error("Error initializing Video.js player:", error);
        toast.error(
          "Failed to initialize the video player. Please contact support."
        );
      }
    } else {
      console.warn(
        "Video reference is not available or player already initialized. Skipping player initialization."
      );
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      window.removeEventListener("resize", updateDimensions); // Clean up dimensions listener
      clearTimeout(timeoutId); // Clear timeout if component unmounts
    };
  }, [videoLoaded, videoContent?.videoContentUrl, env, thumbnailUrl]);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      const checkInterval = 1;
      // Create deep copies of the annotations with their processed status
      const mutableAnnotations = annotations.map(annotation => ({
        ...annotation,
        processed: false,
        timeRange: { ...annotation.timeRange }
      }));
      
      const stopAtAnnotation = () => {
        const currentTime = player?.currentTime();
        const frameRates = frameRate || 30;
        const frameDuration = 1 / frameRates;
        const tolerance = frameDuration / 2;

        // Reset processed annotations if video is rewound
        mutableAnnotations.forEach((annotation, index) => {
          if (annotation.processed && currentTime < annotation.timeRange.startTime) {
            mutableAnnotations[index] = {
              ...annotation,
              processed: false
            };
          }
        });

        // Find the annotation that matches the current time range with tighter precision
        const matchingAnnotation = mutableAnnotations.find(
          (annotation) =>
            annotation?.timeRange?.startTime &&
            !annotation.processed &&
            Math.abs(annotation.timeRange.startTime - currentTime) <= tolerance
        );

        if (matchingAnnotation) {
          player.pause();
          // Update the processed status in our mutable array
          const index = mutableAnnotations.findIndex(a => a === matchingAnnotation);
          if (index !== -1) {
            mutableAnnotations[index] = {
              ...matchingAnnotation,
              processed: true
            };
          }

          if (!isPaused) {
            player.currentTime(matchingAnnotation.timeRange.startTime);
          }

          console.log(
            `Paused accurately at annotation startTime: ${matchingAnnotation.timeRange.startTime.toFixed(4)}, currentTime: ${player.currentTime().toFixed(4)}`
          );
        }
      };

      const intervalId = setInterval(stopAtAnnotation, checkInterval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [annotations, frameRate, isPaused]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      try {
        if (!jwtToken || !videoId) {
          throw new Error("Missing required parameters for fetching annotations.");
        }
        const response = await getCoachFeedback(jwtToken, videoId);
        const data = response.data;

        if (!Array.isArray(data)) {
          throw new Error("Invalid data format received from the server.");
        }

        console.log("Fetched annotation data:", data);
        setAnnotations(data);
        loadVideo();
      } catch (error) {
        console.error("Error fetching annotations:", error.message);
        toast.error(error.message || "An unexpected error occurred while fetching annotations.");
      }
    };

    if (env !== "standalone") {
      fetchAnnotations();
    } else {
      const standaloneVideoFeedback = standAloneFeedbacks.find(
        (feedback) => feedback._id === videoId
      );
      const feedbackData = standaloneVideoFeedback ? [...standaloneVideoFeedback.feedback] : [];
      setAnnotations(feedbackData);
      loadVideo();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [env, jwtToken, videoId, standAloneFeedbacks, loadVideo]);

  const isActive = (annotation) => {
    return (
      currentTime >= annotation.timeRange.startTime &&
      currentTime <= annotation.timeRange.endTime + 0.3
    );
  };

  const controlBarHeight = 20
  const reviewPost = JSON.parse(localStorage.getItem("reviewPost"))

  const filteredAnnotations = annotations.filter((annotation) => {
    const currentTime = playerRef.current?.currentTime(); // Get the current time of the video
    const { startTime, endTime } = annotation.timeRange; // Extract start and end times of the annotation
    return currentTime >= startTime && currentTime <= endTime;
  });

  const [expandedCommentId, setExpandedCommentId] = useState(null); // Track the expanded comment ID
  const toggleComment = (id) => {
    console.log("Id here", id);
    setExpandedCommentId((prev) => (prev === id ? null : id)); // Toggle between the clicked ID and null
  };
  const userRole = localStorage.getItem('BQS_ROLE');

  return (
    <section className={styles.feedback_video}>
      {userRole !== "player" && (
        <h1>{firstname || ""} {lastname || ""}<span> @{dataFormatter(reviewPost?.updatedAt)}</span></h1>)}
      <div className={styles.video_section}>
        <div className={styles.video_container}>
          {!videoLoaded && (
            <div className={styles.loader}>
              <div className={styles.spinner}></div>
            </div>
          )}
          <video
            key={videoKey}
            ref={videoRef}
            className="video-js"
            onLoadedMetadata={() => {
              // Initial setting of video dimensions
              setVideoDimensions({
                width: videoRef.current.offsetWidth,
                height: videoRef.current.offsetHeight,
              });
              updateDimensions();
            }}
            poster={thumbnailUrl} // Placeholder thumbnail
          />

          <Stage
            width={videoDimensions.width}
            height={videoDimensions.height}
            className={styles.konva_stage}
            style={{
              position: "absolute",
              top: `${videoDimensions.top}px`,
              left: `${videoDimensions.left}px`,
              pointerEvents: isPaused ? "auto" : "none", // Disable pointer events when video is not paused
              cursor: drawing ? 'crosshair' : 'default', // Show crosshair cursor when drawing
              touchAction: 'none', // Prevent default touch interactions (like scrolling)
            }}
          >
            <Layer>
              {/* Render filtered annotations */}
              {filteredAnnotations.map((annotation, index) => {
                const x = annotation.markerDetails.coordinates.x1 * videoDimensions.width;
                const y = annotation.markerDetails.coordinates.y1 * videoDimensions.height;
                const width =
                  (annotation.markerDetails.coordinates.x2 - annotation.markerDetails.coordinates.x1) *
                  videoDimensions.width;
                const height =
                  (annotation.markerDetails.coordinates.y2 - annotation.markerDetails.coordinates.y1) *
                  videoDimensions.height;

                return (
                  <Rect
                    key={index}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill="rgba(255, 255, 0, 0.01)"
                    stroke="yellow"
                  />
                );
              })}


            </Layer>
          </Stage>

        </div>

        <div className={styles.sidebar}>
          <div className={styles.annotations_list}>
            <h4>Feedback List</h4>
            {annotations
              .sort((a, b) => {
                const aActive = isActive(a);
                const bActive = isActive(b);

                if (aActive && !bActive) return -1;
                if (!aActive && bActive) return 1;
                return 0;
              })
              .map((annotation) => {
                const truncatedComment = annotation.comments[0].body.slice(0, 50);
                const isExpanded = expandedCommentId === annotation._id; // Check if this comment is expanded

                return (
                  <div
                    key={annotation.id}
                    className={styles.annotation_item}
                  >
                    <div
                      className={styles.annotation_header}
                      style={{
                        backgroundColor: isActive(annotation) ? 'yellow' : '#fff',
                        border: isActive(annotation) ? '2px solid green' : '1px solid gray',
                      }}
                    >
                      <div className={styles.name}>
                        <p className={styles.time_range} style={{ color: isActive(annotation) ? 'green' : 'gray', }}>
                          {annotation.timeRange.startTime.toFixed(2)}s
                        </p>
                        <p>
                          {isExpanded
                            ? annotation.comments[0].body 
                            : `${truncatedComment}`} {/* Show truncated comment */}
                        </p>
                        {annotation.comments[0].body.length > 50 && (
                          <button
                            onClick={() => toggleComment(annotation._id)} // Toggle only this comment
                            className={styles.seeMoreButton}
                          >
                            {isExpanded ? 'See Less' : 'See More'}
                          </button>
                        )}
                      </div>
                    </div>
                    {annotation.isNew && <span className={styles.newLabel}>New</span>}
                  </div>
                );
              })}
          </div>

        </div>
      </div>
    </section>
  );
}

