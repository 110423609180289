import { configureStore } from '@reduxjs/toolkit';
import userSlice from './reducers/userSlice';
import alertSlice from './reducers/alertSlice';
import loginSlice from './reducers/loginSlice'
import videoSlice from './reducers/videoSlice';
import coachSlice from './reducers/coachSlice';
import reportSliceJs from './reducers/reportSlice.js';
import standAloneSlice from './reducers/standaloneSlice.js';
import feedbackSlice from './reducers/feedbackSlice.js';


// import masterSlice from './reducers/masterSlice';

export default configureStore({
  reducer: {
    // master: masterSlice,
    user: userSlice,
    alert: alertSlice,
    login: loginSlice,
    video: videoSlice,
    coach: coachSlice,
    report: reportSliceJs,
    standAlone: standAloneSlice,
    feedback: feedbackSlice,
  },
})