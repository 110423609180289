import { Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import "./ChoosePlan.scss"
import { useNavigate } from 'react-router-dom';
import { PostUpdateReviewSelection } from '../../redux/network/videoApi';
import { POST_UPDATE_REVIEW_SELECTION } from '../../config/endpoints';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice';

const steps = [
  { heading: "Which specific area would you like to work on?", error: "Please select one spcific area." },
  { heading: "Pick your top 3 areas of focus", error: "Please select only 3 araes" },
  // { heading: "Choose the right plan to improve your gameplay", error: "Please select one spcific area." },
]

const forehandOptions = ["Smash", "Clear", "Drop", "Half smash", "Pull drop", "Dribble", "Simple keep", "Net tap", "Drive", "Defensive block", "Serve", "Lift"]
const backhandOptions = ["Clear", "Drop", "Pull drop", "Dribble", "Simple keep", "Net tap", "Drive", "Defensive block", "Serve", "Lift"]
const overheadOptions = ["Smash", "Clear", "Drop", "Half smash"]
const attackOptions = ["Forehand Smash and Tap", "Overhead Smash and Tap", "Defense Block to Attack", "Single Step Attacking Strokes", "Dribble and Smash"]
const defenseOptions = ["Forehand Lift and Defense", "Backhand Lift and Defense", "Keep and Pull Drops", "Clear and Defense", "Both Corner Defense Blocks"]

const plan = [
  { description: "Upto 4 game insights reports monthly" },
  { description: "Access videos & reports in My Journal" },
  { description: "Customized improvement tips" },
]

export default function ChoosePlan() {
  const optionsContainerRef = useRef(null);
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!jwtToken) {
      navigate("/upload")
    }
  }, [jwtToken]);

  const [stepCount, setStepCount] = useState(steps.length);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedArea, setSelectedArea] = useState("")
  const [selectedTechnique, setSelectedTechnique] = useState("forehand");
  const [selectedFootwork, setSelectedFootwork] = useState("attack")
  const [foreHandOptionsSelected, setforeHandOptionsSelected] = useState([])
  const [backHandOptionsSelected, setBackHandOptionsSelected] = useState([])
  const [overHeadOptionsSelected, setOverHeadOptionsSelected] = useState([])
  const [attackOptionsSelected, setattackOptionsSelected] = useState([])
  const [defenseOptionsSelected, setDefenceOptionsSelected] = useState([])
  const [strokeTechniqueOptions, setStrokeTechniqueOptions] = useState([])
  const [footworkOptions, setFootworkOptions] = useState([])
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        navigate("/game-posts/game-videos");
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [isSuccess, navigate]);

  const [selectedPlan, setSelectedPlan] = useState("")
  const [error, setError] = useState("")

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan)
  }

  const handleSelectTechnique = (technique) => {
    setSelectedTechnique(technique)

  }
  const handleSelectFootwork = (footwork) => {
    setSelectedFootwork(footwork)

  }



  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      setError("")
    } else {
      navigate("/game-posts/game-videos");
    }
  }

  const handleExit = () => {
    navigate("/game-posts/game-videos");
  }

  const handleSubmit = () => {
    const reviewPostId = localStorage.getItem("reviewPostId");
    const jwtToken = localStorage.getItem("BQS_TOKEN");

    if (!reviewPostId || !jwtToken) {
      setError("Missing review post ID or JWT token.");
      dispatch(setAlertMessage("Missing review post ID or JWT token."));
      dispatch(setAlertType("error"));
      return;
    }

    const formData = {};

    if (foreHandOptionsSelected.length > 0 || backHandOptionsSelected.length > 0 || overHeadOptionsSelected.length > 0) {
      formData.strokeTechnique = {};
      if (foreHandOptionsSelected.length > 0) {
        formData.strokeTechnique.forehand = foreHandOptionsSelected;
      }
      if (backHandOptionsSelected.length > 0) {
        formData.strokeTechnique.backhand = backHandOptionsSelected;
      }
      if (overHeadOptionsSelected.length > 0) {
        formData.strokeTechnique.overhead = overHeadOptionsSelected;
      }
    }

    if (defenseOptionsSelected.length > 0 || attackOptionsSelected.length > 0) {
      formData.footwork = {};
      if (defenseOptionsSelected.length > 0) {
        formData.footwork.defense = defenseOptionsSelected;
      }
      if (attackOptionsSelected.length > 0) {
        formData.footwork.attack = attackOptionsSelected;
      }
    }

    const url = `${POST_UPDATE_REVIEW_SELECTION}/?postId=${reviewPostId}`;
    const stringifyFd = JSON.stringify(formData);

    axios.post(url, stringifyFd, {
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        console.log("PostUpdateReviewSelection", res);
        if (res.data.code === 200) {
          setIsSuccess(true);
          dispatch(setAlertMessage(res.data.message));
          dispatch(setAlertType("success"));
        } else if (res.data.code === 401) {
          localStorage.clear();
          dispatch(setAlertMessage("Unauthorized"));
          dispatch(setAlertType("error"));
        } else {
          throw new Error(res.data.message || "Something went wrong!");
        }
      })
      .catch(error => {
        console.error("Error", error);
        setError(error?.response?.data?.message || error?.message || "Something went wrong!");
        dispatch(setAlertMessage(error?.response?.data?.message || error?.message || "Something went wrong!"));
        dispatch(setAlertType("error"));
      });
  };



  const handleNext = () => {
    if (activeStep === 0) {
      if (selectedArea === "") {
        setError(steps[0].error)
        return
      } else {
        if (activeStep < stepCount - 1) {
          setError("")
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }

      }
    } else if (activeStep === 1) {
      if (strokeTechniqueOptions.length > 0 || footworkOptions.length > 0) {
        // if (activeStep < stepCount - 1) {
        //  
        //   // setActiveStep(prevActiveStep => prevActiveStep + 1);

        // }
        setError("")
        handleSubmit()
      } else {
        setError("Please select at least one area.");
        return;
      }
    }
    // else if (activeStep === 2) {
    //   handleSubmit()
    //   navigate("/greetings");
    //   return
    // }
  };

  const handleforeHandOptionsSelected = (option) => {
    setError("");
    const isOptionSelected = foreHandOptionsSelected.includes(option);
    const isOptionIncludeInStrokeTechniques = strokeTechniqueOptions.includes(option)

    if (isOptionSelected) {
      setforeHandOptionsSelected((prev) => prev.filter((item) => item !== option));
      if (isOptionIncludeInStrokeTechniques) {
        setStrokeTechniqueOptions((prev) => prev.filter((item) => item !== option))
      }
    } else {
      if (strokeTechniqueOptions.length < 3) {
        setforeHandOptionsSelected((prev) => [...prev, option]);
        setStrokeTechniqueOptions((prev) => [...prev, option])
      } else {
        setError("Please select only 3 areas.");
      }
    }
  };

  const handleBackHandOptionsSelected = (option) => {
    setError("");
    const isOptionSelected = backHandOptionsSelected.includes(option);
    const isOptionIncludeInStrokeTechniques = strokeTechniqueOptions.includes(option)
    if (isOptionSelected) {
      setBackHandOptionsSelected((prev) => prev.filter((item) => item !== option));
      if (isOptionIncludeInStrokeTechniques) {
        setStrokeTechniqueOptions((prev) => prev.filter((item) => item !== option))
      }
    } else {
      if (strokeTechniqueOptions.length < 3) {
        setBackHandOptionsSelected((prev) => [...prev, option]);
        setStrokeTechniqueOptions((prev) => [...prev, option])
      } else {
        setError("Please select only 3 areas.");
      }
    }
  };

  const handleOverHeadOptionsSelected = (option) => {
    setError("");
    const isOptionSelected = overHeadOptionsSelected.includes(option);
    const isOptionIncludeInStrokeTechniques = strokeTechniqueOptions.includes(option)
    if (isOptionSelected) {
      setOverHeadOptionsSelected((prev) => prev.filter((item) => item !== option));
      if (isOptionIncludeInStrokeTechniques) {
        setStrokeTechniqueOptions((prev) => prev.filter((item) => item !== option))
      }
    } else {
      if (strokeTechniqueOptions.length < 3) {
        setOverHeadOptionsSelected((prev) => [...prev, option]);
        setStrokeTechniqueOptions((prev) => [...prev, option])
      } else {
        setError("Please select only 3 areas.");
      }
    }
  };

  const handleAttackOptionsSelected = (option) => {
    setError("");
    const isOptionSelected = attackOptionsSelected.includes(option);
    const isOptionIncludeInFootwork = footworkOptions.includes(option)
    if (isOptionSelected) {
      setattackOptionsSelected((prev) => prev.filter((item) => item !== option));
      if (isOptionIncludeInFootwork) {
        setFootworkOptions((prev) => prev.filter((item) => item !== option))
      }
    } else {
      if (footworkOptions.length < 3) {
        setattackOptionsSelected((prev) => [...prev, option]);
        setFootworkOptions(prev => [...prev, option])
      } else {
        setError("Please select only 3 areas.");
      }
    }
  };

  const handleDefenceOptionsSelected = (option) => {
    setError("");
    const isOptionSelected = defenseOptionsSelected.includes(option);
    const isOptionIncludeInFootwork = footworkOptions.includes(option)
    if (isOptionSelected) {
      setDefenceOptionsSelected((prev) => prev.filter((item) => item !== option));
      if (isOptionIncludeInFootwork) {
        setFootworkOptions((prev) => prev.filter((item) => item !== option))
      }
    } else {
      if (footworkOptions.length < 3) {
        setDefenceOptionsSelected((prev) => [...prev, option]);
        setFootworkOptions(prev => [...prev, option])
      } else {
        setError("Please select only 3 areas.");
      }
    }
  };

  const handleSpecificAreaSelection = (area) => {
    setSelectedArea(area)
    setforeHandOptionsSelected([])
    setBackHandOptionsSelected([])
    setOverHeadOptionsSelected([])
    setattackOptionsSelected([])
    setDefenceOptionsSelected([])
    setStrokeTechniqueOptions([])
    setFootworkOptions([])
  }

  return (
    <div className="chooseplan-container">
      <div className="container">
        <div className="button-container">
          <div>
            <button onClick={() => handlePrevious()}><i className="fa fa-arrow-left"></i>Previous</button>
          </div>
          <div>
            <button onClick={() => handleExit()} className="exit">Exit</button>
          </div>
        </div>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${(activeStep / (stepCount - 1)) * 100}%` }}
          >
            {Array.from({ length: stepCount }, (_, index) => (
              <span
                key={index}
                className={`step ${index <= activeStep ? "active" : ""}`}
              ></span>
            ))}
          </div>
        </div>

        <div className="step-content">
          <h5 className="heading" >{steps[activeStep].heading}</h5>

          {activeStep === 0 && (
            <Grid container spacing={2} className="grid_container">
              <Grid item xs={5}>
                <button
                  className={`specific-area ${selectedArea === "technique" ? "selected" : ""
                    }`}
                  onClick={() => handleSpecificAreaSelection("technique")}
                >
                  Technique
                </button>
              </Grid>
              <Grid item xs={5}>
                <button
                  className={`specific-area ${selectedArea === "footwork" ? "selected" : ""
                    }`}
                  onClick={() => handleSpecificAreaSelection("footwork")}
                >
                  Footwork
                </button>
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && selectedArea === "technique" && (
            <div className='container'>
              <div className='area_container'>
                <Grid container>
                  <Grid item xs={4}>
                    <button
                      className={`specific-technique ${selectedTechnique === "forehand" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectTechnique("forehand")} style={{ borderRight: "2px solid #329633", borderTopLeftRadius: "15px" }}
                    >
                      Forehand
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      className={`specific-technique ${selectedTechnique === "backhand" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectTechnique("backhand")} style={{ borderRight: "2px solid #329633" }}
                    >
                      Backhand
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      className={`specific-technique ${selectedTechnique === "overhead" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectTechnique("overhead")} style={{ borderRight: "none", borderTopRightRadius: "15px" }}
                    >
                      Overhead
                    </button>
                  </Grid>
                </Grid>
              </div>
              <div
                ref={optionsContainerRef}
                className='options_container'
              >

                {selectedTechnique === "forehand" && forehandOptions.map((option, index) => (
                  <div key={index} className={`option_box ${foreHandOptionsSelected.includes(option) ? "selected_option" : ""}`} onClick={() => handleforeHandOptionsSelected(option)}> {foreHandOptionsSelected.includes(option) ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                    <p style={{ margin: "0 0 0 0.5rem" }}>{option}</p></div>
                ))}

                {selectedTechnique === "backhand" && backhandOptions.map((option, index) => (
                  <div key={index} className={`option_box ${backHandOptionsSelected.includes(option) ? "selected_option" : ""}`} onClick={() => handleBackHandOptionsSelected(option)}> {backHandOptionsSelected.includes(option) ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                    <p style={{ margin: "0 0 0 0.5rem" }}>{option}</p></div>
                ))}

                {selectedTechnique === "overhead" && overheadOptions.map((option, index) => (
                  <div key={index} className={`option_box ${overHeadOptionsSelected.includes(option) ? "selected_option" : ""}`} onClick={() => handleOverHeadOptionsSelected(option)}> {overHeadOptionsSelected.includes(option) ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                    <p style={{ margin: "0 0 0 0.5rem" }}>{option}</p></div>
                ))}
              </div>
            </div>
          )}

          {activeStep === 1 && selectedArea === "footwork" && (
            <div className='container'>
              <div className='area_container'>
                <Grid container>
                  <Grid item xs={6}>
                    <button
                      className={`specific-technique ${selectedFootwork === "attack" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectFootwork("attack")} style={{ borderRight: "2px solid #329633", borderTopLeftRadius: "15px" }}
                    >
                      Attack
                    </button>
                  </Grid>
                  <Grid item xs={6}>
                    <button
                      className={`specific-technique ${selectedFootwork === "defense" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectFootwork("defense")} style={{ borderRight: "none", borderTopRightRadius: "15px" }}
                    >
                      Defense
                    </button>
                  </Grid>
                </Grid>
              </div>
              <div
                ref={optionsContainerRef}
                className='options_container'
                style={{ display: "flex", flexWrap: "wrap", padding: "2rem 1rem", justifyContent: "center", alignItems: "flex-start" }}
              >

                {selectedFootwork === "attack" && attackOptions.map((option, index) => (
                  <div key={index} className={`option_box ${attackOptionsSelected.includes(option) ? "selected_option" : ""}`} onClick={() => handleAttackOptionsSelected(option)}> {attackOptionsSelected.includes(option) ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                    <p style={{ margin: "0 0 0 0.5rem" }}>{option}</p></div>
                ))}

                {selectedFootwork === "defense" && defenseOptions.map((option, index) => (
                  <div key={index} className={`option_box ${defenseOptionsSelected.includes(option) ? "selected_option" : ""}`} onClick={() => handleDefenceOptionsSelected(option)}> {defenseOptionsSelected.includes(option) ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                    <p style={{ margin: "0 0 0 0.5rem" }}>{option}</p></div>
                ))}
              </div>
            </div>
          )}

          {/* {activeStep === 2 && (
            <div className='plan_container'>
              <div className={`left_box ${selectedPlan === "saverPlan" ? "selectedPlan" : ""}`} onClick={() => handleSelectPlan("saverPlan")}>
                <div className='left_top'><p>Saver plan</p></div>
                <div className="left_container">
                  <div className='top'>
                    <div className='left'>
                      <h1><span>3</span></h1>
                      <p>Months</p>
                    </div>
                    <div className='right'>
                      <p>₹ 2000</p>
                      <h1>₹ 1000</h1>
                      <p>Per month</p>
                    </div>
                  </div>
                  <div className='bottom'>
                    {plan.map((ele, index) => {
                      return (
                        <div key={index}>
                          <p>
                            <i className="fa-solid fa-check icon"></i>
                            {ele.description}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='right_box'>
                <div className='right_top'></div>
                <div className={`right_container ${selectedPlan === "nonSaverPlan" ? "selectedPlan" : ""}`} onClick={() => handleSelectPlan("nonSaverPlan")}>
                  <div className='top'>
                    <div className='left'>
                      <h1><span>1</span></h1>
                      <p>Month</p>
                    </div>
                    <div className='right'>
                      <p>₹ 2000</p>
                      <h1>₹ 1500</h1>
                      <p>Per month</p>
                    </div>
                  </div>
                  <div className='bottom'>
                    {plan.map((ele, index) => {
                      return (
                        <div key={index}>
                          <p>
                            <i className="fa-solid fa-check icon"></i>
                            {ele.description}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <p className='error'>{error}</p>
        <div className="button-bottm">
          <div>
            {activeStep !== stepCount && (
              <button
                className="next-button"
                onClick={handleNext}
              >
                {activeStep === 1 ? "SUBMIT" : "NEXT"}
                <i className="fa fa-arrow-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}