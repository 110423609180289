import React, { useEffect } from 'react'
import './About.scss'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='About_us'>
            <div className='bannar'>
                <div className='container'>
                    <h1 className='heading'>About Us</h1>
                </div>
            </div>
            <div className='About_us_content'>
                <div className='container'>
                    <div className='top_section'>
                        <p> Visist AI is reshaping sports technology with our innovative Visual Intelligence Platform, delivering game-changing solutions like Video Journaling, AI-driven feedback, smart court automation, injury prediction and more.
                        </p>
                        <p>We combine the brilliance of AI with sports science to create tools that help athletes achieve their full potential and unlocks insights that were once reserved for the elite.
                        </p>
                        <p>Driven by the vision to empower a million grassroots athletes, we are building the future of sports —where every athlete can train smarter, perform better, and stay ahead of the game and every academy can improve their ROI on investing on athlete trainings and performance targets.
                        </p>
                        {/* <a href='Visist.ai' target='_blank'>Visist.ai</a> */}
                    </div>
                    {/* <div className='bottom_section'>
                        <div className='queote_container'>
                            <p className='double_queote rotote_queote'>"</p>
                            <p className='queote'>If we dare to win, we<br />should also dare to lose.</p>
                            <div>
                                <p className='name'>- Lee Chong Wei</p>
                                <p className='double_queote flex_end'>"</p>
                            </div>
                        </div>
                        <img src='assets/About_us.avif' alt='about_us' />
                    </div> */}

                     <div className='bottom_section'>
                          <h1>VISIST AI TECHNOLOGIES PRIVATE LIMITED</h1>
                          <p>
                            62/2-1 ,8th Main, 132, Embassy Heritage, Malleswaram, Bengaluru 560055, INDIA.
                          </p>
                          <p>
                            CIN: U63119KA2024PTC187267, GST:29AAKCV0945N1ZM
                          </p>
                     </div>
                    <div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About