import React, { useEffect, useState } from 'react';
import styles from './GameVideos.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Pagination, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { deleteGamePost, getAllPostCounts, getFetchGamePostFileThumbnail, getVideos, markGamePostFav } from '../../../redux/network/videoApi';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreenDialog from '../../VideoPlayerModel/VideoPlayerModel';
import { GET_POST_CONTENT } from '../../../config/endpoints';
import thumbnail from './thumbnail.jpg';
import { deleteVideoPost, error_GetFetchVideoContent, isStarted_GetFetchVideoContent, success_GetFetchVideoContent, toggleFavVideoPost, toggleVideoSortDropdown } from '../../../redux/reducers/videoSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setAlertMessage, setAlertType } from '../../../redux/reducers/alertSlice';
import { buyReviewCredits, getUserProfile } from '../../../redux/network/userApi';
import UploadeVideoModel from '../../UploadVideoModel/UploadeVideoModel';
import { setVideoUploadDialogOpen } from '../../../redux/reducers/loginSlice';
import CustomDropdown from '../../CustomeDropdown/CustomDropdown';
import CoachFeedback from '../../CoachFlow/CoachFeedback';
import CloseIcon from '@mui/icons-material/Close';
import { addStandAloneVideoFunction } from '../../../redux/network/standAloneApi';

const dropdownOptions = [
    { value: 'default', label: 'Default' },
    { value: 'created', label: 'Uploaded' },
    { value: 'reviewRequested', label: 'Requested' },
    { value: 'reviewed', label: 'Completed' },
    { value: 'favourites', label: 'Favourites' },
];


const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

const getTimeRange = (range) => {
    const now = new Date();
    let start, end;
    switch (range) {
        case 'lastOneWeek':
            start = new Date(now.setDate(now.getDate() - 7));
            end = new Date();
            break;
        case 'lastMonth':
            start = new Date(now.setMonth(now.getMonth() - 1));
            end = new Date();
            break;
        case 'last3Months':
            start = new Date(now.setMonth(now.getMonth() - 3));
            end = new Date();
            break;
        default:
            start = null;
            end = null;
    }
    return { start: start?.getTime(), end: end?.getTime() };
}

const GameVideos = () => {
    const { pathname } = useLocation();
    console.log("location", pathname)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const userId = localStorage.getItem("BQS__ID");
    const { isStarted, isError, error, data } = useSelector(state => state.video.video);
    console.log("standalone video---->", data)

    const { resetToDefault } = useSelector(state => state.video.videoSortDropdown);
    const postCountData = useSelector(state => state.video.videosCount);
    const [animatedFavs, setAnimatedFavs] = useState({});
    const [deletedPostId, setDeletedPostId] = useState(null);
    const [open, setOpen] = useState(false);
    const [videoThumbnails, setVideoThumbnails] = useState({});
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const COUNT = Math.ceil(postCountData?.data?.count / limit)
    const [sortOption, setSortOption] = useState('default');
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [avoidUpdateTrigger, setAvoidUpdateTrigger] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const env = process.env.REACT_APP_ENV;

    useEffect(() => {
        setSortOption("default");
        setPage(1);
        setLimit(100);
        setSkip(0);
    }, [resetToDefault])

    useEffect(() => {
        switch (sortOption) {
            case 'oldToNew':
                setSkip(0)
                setPage(1)
                break;
            case 'newToOld':
                setSkip(0)
                setPage(1)
                break;
            case 'created':
                setSkip(0)
                break;
            case 'reviewRequested':
                setSkip(0)
                break;
            case 'reviewed':
                setSkip(0)
                break;
            case 'favourites':
                setSkip(0)
                break;
            default:
        }
    }, [sortOption]);

    // useEffect(() => {
    //     if (jwtToken) {
    //         dispatch(getUserProfile(jwtToken))
    //     }
    // }, [jwtToken, dispatch])


    const handleChange = (event, value) => {
        setPage(value);
        setSkip((value - 1) * limit);
        window.scrollTo(0, 0);
    };

    // Function to construct the conditions
    const constructConditions = () => {
        const { start, end } = getTimeRange(sortOption);
        let conditions = { "poster": userId };

        // Add time range condition if applicable
        if (start && end) {
            conditions.createdAt = { start, end };
        }
        if (sortOption === "created") {
            conditions.state = "created"
        } else if (sortOption === "reviewRequested") {
            conditions.state = "review_requested"
        } else if (sortOption === "reviewed") {
            conditions.state = "reviewed"
        } else if (sortOption === "favourites") {
            conditions.fav = true;
        } else {
            conditions.state = ""
        }

        return conditions;
    }

    //All Posts Count
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            const conditions = constructConditions();
            dispatch(getAllPostCounts(jwtToken, {
                "condition": conditions,
            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger, skip, limit, resetToDefault]);

    //All Posts Details
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            const conditions = constructConditions();
            dispatch(getVideos(jwtToken, {
                "condition": conditions,
                "sort": {
                    "updatedAt": -1
                },
                "skip": skip,
                 "limit": limit,
            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger, skip, limit, resetToDefault]);


    useEffect(() => {
        if (data?.length > 0 && jwtToken && env !== "standalone") {

            const fetchThumbnail = async (reviewPostId, tnPath) => {
                try {
                    const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
                    setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
                    console.error("Game Videos");
                } catch (error) {
                    console.error('Error fetching thumbnail:', error);
                }
            };
            data?.forEach((item) => {
                fetchThumbnail(item._id, item.game[0]?.tnPath);
            });
        }
    }, [data, jwtToken, userId]);

    async function handlePlayClick(video,) {
        setSelectedVideo(video)
        const postId = video._id
        const videoLinkInObjectStore = video.game[0].path;
        console.log("videoLinkInObjectStore--------------", videoLinkInObjectStore);
        localStorage.setItem("videoLinkInObjectStore" , videoLinkInObjectStore);
        localStorage.setItem("reviewPostId", postId);
        localStorage.setItem("reviewPost", JSON.stringify(video));
        localStorage.setItem("prevPath", JSON.stringify(pathname));

        if (video.state === "reviewed") {
            navigate(`feedback/${postId}`)
        } else {
            // if (data.length < videocoutn)
            // {
                navigate(`request/${postId}`)
            //}
            // else {

            //     const reviewedVideoData =  dispatch(getVideos(jwtToken, {
            //         "condition": {
            //             "poster": userId,
            //             "state" : 'reviewed'
            //         },
            //         "sort": {
            //             "updatedAt": -1
            //         },
            //         "skip": skip,
            //     }));

            //     console.log("reviewed video data is ", reviewedVideoData);

            //     alert("You have reached max number of review requests under your subscription");
            // }
            
        }


        //stand alone code 
        if (env === "standalone") {
            return null;
        }

        // //const url = `${GET_POST_CONTENT}?postId=${postId}&type=game`;
        const url = localStorage.getItem("videoLinkInObjectStore");

        dispatch(isStarted_GetFetchVideoContent());



        try {
            const response = await fetch(url, {
                method: "GET" //,
                // headers: {
                //     'Authorization': `Bearer ${jwtToken}`,
                // },
            });
          
            if (!response.ok) {
                throw new Error('Failed to fetch video');
                
            }else{
                console.log(response)
            }

            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            });
            const responseBlob = await new Response(stream).blob();
            const file = new File([responseBlob], 'video.mp4', { type: 'video/mp4' });
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.addEventListener("load", () => {
                const res = fr.result;
                dispatch(success_GetFetchVideoContent(res));
            });
        } catch (error) {
            dispatch(error_GetFetchVideoContent(error.message || "Error while fetching video"));
            console.error('Error fetching video:', error);
        }
    }

    function RenderNoVideosView() {
        return (
            <div className={styles.no_video_view_cont}>
                <div>
                    <h6>Once Uploaded your gameplay video will appear here</h6>
                    {/* <h1>No Videos uploaded .....</h1> */}
                    {/* <p>Click <Link to="/upload">here</Link> to upload your gameplay video and get a detailed report.</p> */}
                </div>
            </div>
        );
    }

    function RenderNoVideosWithSearchCondition({ sortOption }) {
        let searchKey

        if (sortOption === "created") {

            searchKey = "Uploaded"
        } else if (sortOption === "reviewRequested") {
            searchKey = "Requested"
        } else if (sortOption === "reviewed") {
            searchKey = "Completed"
        } else if (sortOption === "favourites") {
            searchKey = "Favourites"
        }
        else {
            searchKey = ""
        }

        return (
            <div className={styles.no_video_view_cont}>
                <div>
                    <h5>No video found with provided search key {searchKey}</h5>
                    {/* <h5>No uploaded videos found</h5> */}
                    {/* <p>Click <Link to="/upload">here</Link> to upload your gameplay video and get a detailed report.</p> */}
                </div>
            </div>
        );
    }

    async function handleClickFav(postData) {
        if (env === "standalone") {
            return null;
        }

        const postId = postData._id;
        const favToggle = !postData.fav;
        const res = await markGamePostFav(jwtToken, postId, favToggle);

        if (res && res.code === 200) {
            setAnimatedFavs(prev => ({ ...prev, [postId]: true }));
            dispatch(toggleFavVideoPost(postId));

            setTimeout(() => {
                if (data.length === 0) {
                    dispatch(toggleVideoSortDropdown())
                }
                setAnimatedFavs(prev => ({ ...prev, [postId]: false }));
            }, 300);
        } else {
            dispatch(setAlertMessage(res.message || "Something went wrong"));
            dispatch(setAlertType("error"));
        }
    }

    async function handleClickDel(postData) {
        if (env === "standalone") {
            return null;
        }

        const res = await deleteGamePost(jwtToken, postData._id);
        setAvoidUpdateTrigger(true);

        if (res && res.code === 200) {
            setTimeout(() => {
                setAvoidUpdateTrigger(false);
            }, 1000);

            // Trigger the animation by setting the deletedPostId state
            setDeletedPostId(postData._id);
            // Delay the actual deletion to allow the animation to play
            setTimeout(() => {
                dispatch(deleteVideoPost(postData._id));
                setDeletedPostId(null);

                // Check if current page is empty after deletion
                if (data.length === 1 && page > 1) {
                    setAvoidUpdateTrigger(false) // set this false to re-render component
                    setPage(prevPage => prevPage - 1);
                    setSkip(prevSkip => prevSkip - limit);
                }

                setUpdateTrigger(prev => !prev); // Toggle updateTrigger to re-render component
            }, 800); // Adjust the delay to match the animation duration



        } else {
            dispatch(setAlertMessage(res.message || "Something went wrong"));
            dispatch(setAlertType("error"));
        }
    }

    const handleSortChange = (newSortOption) => {
        setSortOption(newSortOption);
        setPage(1)
        setSkip(0)
        setUpdateTrigger(prev => !prev); // Trigger update to refetch data
    };

    async function handleClickBuyCredit() {
        const res = await buyReviewCredits(jwtToken);

        if (res && res.code === 200) {
            dispatch(getUserProfile(jwtToken));
            dispatch(setAlertMessage(res.message || "Credit purchased successfully"));
            dispatch(setAlertType("success"));
        } else {
            dispatch(setAlertMessage(res.message || "Something went wrong"));
            dispatch(setAlertType("error"));
        }
    }

    const handleClickUploadVideo = () => {

        // if (data.length < videocoutn )
        // {
            if (env === "standalone") {
                dispatch(addStandAloneVideoFunction())
            } else {
                dispatch(setVideoUploadDialogOpen(true))
            }
        //}

        // else {
        //     alert("You have reached max number of uploads under your subscription" );
        // }

    }

    function handleClickViewReport(postData) {
        localStorage.setItem("reviewPostData", JSON.stringify(postData));
        navigate("/game-feedback/coach-feedback")
    }

    const closeModal = () => {
        setSelectedVideo(null);
        setIsModalOpen(false);
    };


    const [standloan, setStandData] = useState([]);

    useEffect(() => {
        fetch('/Video.json') // Use the relative path if the JSON is in the public folder
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => setStandData(standloan))
            .catch((error) => console.error('Error fetching the JSON:', error));
    }, []);

    const videocoutn = postCountData?.data?.count

    return (
        <>
            <div className={styles.videos_section}>
                <div className={styles.filters}>
                    {/* <div style={{ display: "flex", alignItems: 'center', gap: "2rem" }}>
                    <div className={styles.searchbox_short}>
                        <i style={{ marginRight: "1rem" }} className="fa fa-search"></i>
                        <input type="text" placeholder="Search by age and athlete" />
                    </div>
                    <i class="fa-solid fa-arrow-up-wide-short" style={{ color: "#777", fontSize: "28px" }}></i>

                </div> */}
                    <h1>My Videos</h1>

                    {/* <div className={styles.counts}>
                        Remaining video count <span>{data?.length || 0}/{videocoutn || 0}</span>
                    </div> */}
                </div>
                {jwtToken && (
                    <>
                        <div className={styles.header_section}>

                        </div>
                        {isStarted ? (
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                                <CircularProgress /> <p>Loading....</p>
                            </Box>
                        ) : isError ? (
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                                <p className={styles.error}>{error}</p>
                            </Box>
                        ) : data?.length > 0 ? (
                            <div>

                                <div className={styles.wrp}>
                                    {data?.map((ele, index) => {
                                        // alert(JSON.stringify(data[0].game[0].path.split('/').pop().split('.').shift()))
                                        console.log(`Video ${index}`, ele);
                                        const thumbnailUrl = videoThumbnails[ele._id] || thumbnail;
                                        const isFavAnimated = animatedFavs[ele._id];

                                        return (
                                            <div className={`${styles.video_wrapper} ${deletedPostId === ele._id ? styles.deleting : ''}`}
                                                key={ele._id}>
                                                <div className={styles.data_icons_container}>
                                                    <div className={styles.date_cont}>
                                                        <span></span>
                                                        <p>{dataFormatter(ele.updatedAt)}</p>
                                                       
                                                    </div>
                                                   
                                                    <div className={styles.icons_cont}>
                                                        <i
                                                            className={`fa-${ele.fav ? 'solid' : 'regular'} fa-bookmark ${styles.favIcon} ${isFavAnimated ? styles.animate : ''}`}
                                                            onClick={() => handleClickFav(ele)}
                                                        ></i>
                                                        <i className="fa-solid fa-delete"></i>
                                                        <DeleteForeverIcon sx={{ color: "#247E45", cursor: "pointer" }} onClick={() => handleClickDel(ele)} />
                                                    </div>
                                                  
                                                </div>
                                                {/* <p></p> */}
                                                <div className={styles.video_box} >
                                                    <div className={styles.video_cont} style={{ backgroundImage: `url(${thumbnailUrl})` }}>
                                                        {
                                                            ele.state === "created" ? <Button onClick={() => handlePlayClick(ele)}>Ready to Submit</Button> : ele.state === "reviewed" ? <Button onClick={() => handlePlayClick(ele)}>View report</Button> : <Button style={{ backgroundColor: "#E0E1E6", color: "#000000", }} disabled={true}>Waiting for Feedback</Button>
                                                        }
                                                    </div>
                                                    <div className={styles.icons}>
                                                        <div className={styles.play_icon} onClick={() => handlePlayClick(ele)}>
                                                            <FontAwesomeIcon icon={faCirclePlay} />
                                                            {/* <span>Play</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* {env !== "standalone" &&
                                    <Stack spacing={2} sx={{ marginBlock: "2rem", alignItems: "end" }}>
                                        <Pagination count={COUNT} page={page} onChange={handleChange} size='large' />
                                    </Stack>

                                } */}
                            </div>
                        ) : (
                            sortOption == "favourites" || sortOption == "created" || sortOption == "reviewRequested" || sortOption == "reviewed" ? <RenderNoVideosWithSearchCondition sortOption={sortOption} /> : <RenderNoVideosView />
                        )}
                    </>
                )}
                {!jwtToken && <RenderNoVideosView />}

                <div className={styles.add_video_icon} onClick={handleClickUploadVideo}>
                    <i className="fa-solid fa-plus"></i>
                </div>

               
                <UploadeVideoModel/> 


                {selectedVideo && (
                    <Dialog
                        open={isModalOpen}
                        onClose={closeModal}
                        maxWidth={false} // Disable default maxWidth
                        fullScreen
                        sx={{
                            '& .MuiDialog-paper': {
                                margin: 0, // Remove default margin
                                width: '100%', // Full width
                                height: '100%', // Full height
                                maxWidth: '100%', // Prevent max-width limitation
                            },
                        }}
                    >
                        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={closeModal}
                                aria-label="close"
                                style={{
                                    position: 'relative',
                                    right: 0,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {/* Video feedback or content component */}
                            <CoachFeedback
                                videoId={selectedVideo._id}
                            />
                        </DialogContent>

                    </Dialog>
                )}
            </div>
        </>
    );
}

export default GameVideos