import React from 'react'
import './ProfileSidebar.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { userLogout } from '../../../redux/network/userApi';
import { setIsLoginDialog } from '../../../redux/reducers/loginSlice';
import { useDispatch } from 'react-redux';

const ProfileSidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    //Logout User
    const handleLogout = (e) => {
        userLogout(jwtToken).then(res => {
            console.log(res)
            return res.json();
        }).then(response => {
            //clear session
            localStorage.clear()

        }).catch(error => {
            //clear session
            localStorage.clear()

        }).finally(() => {
            // setAnchorEl(null);
            //clear session
            dispatch(setIsLoginDialog(true))
            localStorage.clear()
            navigate("/");
        })
    }

    return (
        <div className='profileSidebar_container'>
            <div className='content'>
                <ul>
                    <li>
                        <NavLink to='/profile/personal_details' className={({ isActive }) => `${isActive ? 'activeLink link' : 'link'}`}><i className="fa-solid fa-user"></i> <span>Profile</span></NavLink>
                    </li>
                    <li>
                        {/* <NavLink to='/profile/membership' className={({ isActive }) => `${isActive ? 'activeLink link' : 'link'}`}><i className="fa-solid fa-user"></i> <span>Membership</span></NavLink> */}
                    </li>
                    <li>
                        {/* <NavLink to='/profile/report' className={({ isActive }) => `${isActive ? 'activeLink link' : 'link'}`}><i className="fa-solid fa-user"></i> <span>Report</span></NavLink> */}
                    </li>
                    <li>
                        {/* <NavLink to='/profile/support' className={({ isActive }) => `${isActive ? 'activeLink link' : 'link'}`}><i className="fa-solid fa-user"></i> <span>Support</span></NavLink> */}
                    </li>
                </ul>
                <button className='logout_button' onClick={handleLogout}>
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    <span>Log Out</span>
                </button>

            </div>
        </div >
    )
}

export default ProfileSidebar