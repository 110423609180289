import React, { useState } from 'react'
import GamePostSidebar from '../GamePostSidebar/GamePostSidebar'
import { Outlet } from 'react-router-dom'
import styles from './GamePostOutlet.module.scss'

const GamePostOutlet = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <div className={styles.GamePostOutlet}>
            <div className={`${styles.sidenav} ${isMenuOpen ? styles.open : ''}`} >
                <GamePostSidebar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </div>

            <div className={styles.Outlet}>
                <div className={styles.toggle_button} onClick={toggleMenu}>
                    <button className={isMenuOpen ? styles.menuicons : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

export default GamePostOutlet