import React from 'react'
import styles from './GameFeedbackSidebar.module.scss'
import { NavLink, useNavigate } from 'react-router-dom'

const GameFeedbackSidebar = () => {
    const navigate = useNavigate()

    const handleClickBackButton = () => {
        navigate("/game-posts/game-reports")
    }

    return (
        <div className={styles.GameFeedbackSidebar}>
            <div className={styles.backButton} onClick={handleClickBackButton}> <i className="fa-solid fa-arrow-left"></i><span>Back</span></div>
            <hr />
            <ul>
                <li>
                    <NavLink
                        to="coach-feedback"
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-magnifying-glass"></i>
                        Coach Feedback</NavLink>
                </li>
                <li>
                    <NavLink
                        to="highlights"
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-magnifying-glass"></i>
                        Highlights</NavLink>
                </li>
                <li>
                    <NavLink
                        to="stats"
                        className={({ isActive }) => (isActive ? styles.active : "")}
                    >
                        <i className="fa-solid fa-magnifying-glass"></i>
                        Stats</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default GameFeedbackSidebar