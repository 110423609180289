import React from 'react';
import styles from './GameFeedbackOutlet.module.scss';
import GameFeedbackSidebar from '../GameFeedbackSidebar/GameFeedbackSidebar'
import { Outlet } from 'react-router-dom';



const GameFeedbackOutlet = () => {
    return (
        <div className={styles.GameFeedbackOutlet}>
            <GameFeedbackSidebar />
            <div className={styles.hrLine}></div>
            <Outlet />
        </div>
    )
}

export default GameFeedbackOutlet