import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        isLoginDialog: false,
        isVideoUploadDialogOpen: false,
        isUploadGuidelineDialogOpen: false
    },
    reducers: {
        setIsLoginDialog(state, action) {
            console.log(action)
            state.isLoginDialog = action.payload;
        },
        setVideoUploadDialogOpen(state, action) {
            state.isVideoUploadDialogOpen = action.payload;
        },
        setUploadGuidelineVideoDialogOpen(state, action) {
            state.isUploadGuidelineDialogOpen = action.payload;
        },
    }
})
export const { setIsLoginDialog, setVideoUploadDialogOpen, setUploadGuidelineVideoDialogOpen } = loginSlice.actions;
export default loginSlice.reducer;