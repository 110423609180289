const handleSuccessError = (subCode) => {
    switch (subCode) {
        case 0:
            return "Operation completed successfully.";
        default:
            break;
    }

}


const handleAuthError = (subCode) => {
    switch (subCode) {
        case 1:
            return "Invalid or expired login session. Please log in again.";
        case 2:
            return "You don't have permission to perform this action.";
        case 3:
            return "Please log in to access this feature.";
        case 4:
            return "Failed to verify your login session. Please log in again.";
        default:
            break;
    }
};

const handleSubjectError = (subCode) => {
    switch (subCode) {
        case 11:
            return "Required information is missing. Please provide all necessary details.";
        case 12:
            return "The requested item does not exist.";
        default:
            break;
    }
};

const handleMongoError = (subCode) => {
    switch (subCode) {
        case 21:
            return "The provided information already exists in our system.";
        case 22:
            return "The requested data could not be found.";
        case 23:
            return "An error occurred while processing the data.";
        case 30:
            return "An unexpected error occurred. Please try again later.";
        case 31:
            return "The provided information is not valid. Please check and try again.";
        case 32:
            return "Cast error";
        case 40:
            return "Unknown error";
        default:
            break;
    }
};

const handleRequestValidationError = (subCode) => {
    switch (subCode) {
        case 41:
            return "Please fill in all required fields.";
        case 42:
            return "The provided information is not valid. Please check and try again.";
        case 43:
            return "The field contains invalid characters. Please use only letters, numbers, and allowed special characters.";
        case 44:
            return "Request validation error, only alphabets, numbers or underscore allowed";
        case 45:
            return "Request validation error, only alphabets, numbers or space allowed";
        default:
            break;
    }
};

const handleAxiosError = (subCode) => {
    switch (subCode) {
        case 51:
            return "An error occurred on our end. Please try again later.";
        case 52:
            return "No response received from the server. Please check your internet connection and try again";
        case 53:
            return "An unexpected error occurred. Please try again later.";
        default:
            break;
    }
};


const handleGeneric = (subCode) => {
    switch (subCode) {
        case 0:
            return "Response data prepared successfully.";
        case 101:
            return "An error occurred while preparing the response. Please try again later.";
        default:
            break;
    }
};

const handleAllGenericErrors = (response) => {
    const subCode = response.subCode;


    // Handling generic errors

    if (subCode == 0) {
        return handleSuccessError(subCode);
    } else if (subCode >= 1 && subCode <= 10) {
        return handleAuthError(subCode);
    } else if (subCode >= 11 && subCode <= 20) {
        return handleSubjectError(subCode);
    } else if (subCode >= 21 && subCode <= 40) {
        return handleMongoError(subCode);
    } else if (subCode >= 41 && subCode <= 50) {
        return handleRequestValidationError(subCode);
    } else if (subCode >= 51 && subCode <= 60) {
        return handleAxiosError(subCode);
    } else if (subCode >= 101 && subCode <= 200) {
        return handleGeneric(subCode);
    } else {
        return response?.message || "Unknown error";
    }
};



export const handleOtpError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "OTP generated and sent successfully.";
        case 201:
            return "An error occurred while generating the OTP. Please try again later.";
        case 202:
            return "Please sign up";
        case 203:
            return "An error occurred while processing the OTP. Please try again later.";
        case 204:
            return "Too many attempts. Your account is temporarily locked. Please contact support for assistance.";
        case 205:
            return "Already Signed Up. Please Sign in.";
        case 206:
            return "Your account is not active. Please contact support for assistance.";
        case 207:
            return "OTP is not supported for your user role.";
        case 208:
            return "Self-registration is not allowed for your user role.";
        case 209:
            return "Number isn't whitelisted. Please contact support@visist.ai";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }
};

export const handleRegisterError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }

    switch (response.subCode) {
        case 0:
            return "Sign Up successful. Welcome!";
        case 201:
            return "An error occurred during Signing Up. Please try again.";
        case 202:
            return "You are not currently registered. Please Sign Up.";
        case 203:
            return "The provided user account could not be found.";
        case 204:
            return "Incorrect OTP entered. Please try again.";
        case 205:
            return "Incorrect OTP entered. Your account is temporarily locked. Plea";
        case 21.1 :
            return "The provided email-id is already in use. Please use another email-id.";
        case 207:
            return "The provided phone number is already in use. Please use another ID.";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }

};

export const handleLoginError = (response,) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "Sign In successful. Welcome back!";
        case 201:
            return "An error occurred during Sign In. Please try again.";
        case 202:
            return "The provided user account could not be found.";
        case 203:
            return "The user account is not registered. Please Sign Up first.";
        case 204:
            return "Your account is currently inactive. Please contact support for assistance.";
        case 205:
            return "Your account is registered without a password. Please contact support for assistance.";
        case 206:
            return "Incorrect password entered. Please try again.";
        case 207:
            return "Incorrect password entered. Your account is temporarily locked. Please try again later.";
        case 208:
            return "Incorrect OTP entered. Please try again.";
        case 209:
            return "Incorrect password entered. Your account is temporarily locked. Please try again later.";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }
};

export const handleLogoutError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "user logged out successfully";
        case 201:
            return "error while logging out user";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response.message || "Unknown error";
    }
};

export const handleUploadError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "Video Uploaded successfully";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }
};

export const handleGetProfileInfoError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "User info fetch succesfully";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }
};

export const handleUpdateProfileInfoError = (response) => {
    if (response.subCode === undefined) {
        if (response.code === 200) {
            response.subCode = 0
        } else {
            return response?.message || "Unknown error"
        }
    }
    switch (response.subCode) {
        case 0:
            return "Provide Updated successfully";
        default:
            // If the response does not contain OTP errors, use generic error handling
            const genericError = handleAllGenericErrors(response);
            return genericError || response?.message || "Unknown error";
    }
};
