import { createSlice } from "@reduxjs/toolkit";

export const standAloneSlice = createSlice({
    name: "standalone",
    initialState: {
        feedbacks: {
            feedbackData: [],
        },
    },
    reducers: {
        addStandaloneFeedback: (state, action) => {
            // state.feedbacks.feedbackData.filter(feedback => feedback._id !== action.payload._id).
            state.feedbacks.feedbackData = state.feedbacks.feedbackData.some(fb => fb._id === action.payload._id)
                ? state.feedbacks.feedbackData.map(fb => fb._id === action.payload._id ? action.payload : fb)
                : [...state.feedbacks.feedbackData, action.payload];
            // state.feedbacks.feedbackData = [...state.feedbacks.feedbackData, action.payload];
        },

    }
})

export const { addStandaloneFeedback } = standAloneSlice.actions

export default standAloneSlice.reducer;
